h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
    font-family: $font-family-base;
}

h2{
    font-size: 1.5rem;
}

h3{
    font-size: 1.25rem;
}

.fs-2{
    font-size: 1.5rem !important;
}

.fs-3{
    font-size: 1.25rem !important;
}

.mid-small{
    font-size: 0.875rem !important;
}

.super-small{
    font-size: 0.75rem !important;
}