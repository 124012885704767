
.sidebar-toggle{
  color: $hamburger-color;
  background: $hamburger-color;
}

.hamburger{
  color: $black;
}

.sidebar.toggled ~ .main .sidebar-toggle{
  background: transparent;
  .hamburger {
    color: $hamburger-color;
  }
}