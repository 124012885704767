.breadcrumb-item {
    a {
        color: $breadcrumb-color;
        font-weight: $font-weight-normal;
    }

    &.active {
        color: $breadcrumb-active-color;
        font-weight: $font-weight-bold;
    }

    &+ .breadcrumb-item{
        padding-left: .2rem;
        &::before {
            padding-right: 0;
        }
    }
}