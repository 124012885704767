.nav-tabs {
  border: 0;
  position: relative;
  z-index: 1;
}

.tab {
  margin-bottom: ($spacer * 2);
  .nav-tabs .nav-link {
    background: $tab-link-bg;
    color: $tab-link-color;
    padding: $tab-link-padding;
    border: 0;

    &.active {
      background: $tab-link-active-bg;
      color: $tab-link-active-color;
    }

    &:hover:not(.active) {
      color: $link-color;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .tab-content {
    background: $white;
    padding: $tab-content-padding;
    box-shadow: $box-shadow;
    border-radius: $tab-content-border-radius;

    p:last-child {
      margin-bottom: 0;
    }
  }
}

@each $color, $value in $theme-colors {
  .tab-#{$color} {
    @include tab-variant($value);
  }
}

.tab-title {
  font-size: $font-size-lg;
}

.tab-vertical .nav-tabs {
  float: left;
  flex-direction: column;

  .nav-link {
    @include border-start-radius($border-radius);
    @include border-end-radius(0);
  }
}

.tab-vertical .tab-content {
  overflow: auto;
}

.nav.nav-tabs{
  display: flex;
  .nav-item{
    flex: 1 1;
  }
  
  .nav-link{
    border: 0 none;
    border-bottom: solid 1px #CCC;
    font-size: .8rem;
    width: 100%;
    height: 100%;
    color: var(--bs-dark);
    &.active{
      border-bottom: solid 3px var(--bs-secondary);
      color: var(--bs-primary);
    }
  }
}