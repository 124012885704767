.btn-pill {
  border-radius: 10rem;
}

.btn-square {
  border-radius: 0;
}

.btn-link:not(:hover) {
  text-decoration: none;
}

.btn .feather {
  width: 14px;
  height: 14px;
}

.btn {
  &-close {
    &.small {
      width: 0.2em;
      height: 0.2em;
    }
  }
  &-primary-gradient {
    background: linear-gradient(to right, var(--bs-warning), var(--bs-primary), var(--bs-secondary));
    color: var(--bs-white);
    &:hover {
      color: var(--bs-white);
      box-shadow: 0 15px 20px rgba(156, 62, 150, 0.3);
      transform: translateY(3px);
    }
  }
  &.btn-icon {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: center;
  }
  &-common-size{
    box-sizing: border-box;
    min-width: 6.25rem;
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-color($white);
    @include button-color-hover($white);
    @include button-color-active($white);
  }

  .btn-outline-#{$color} {
    @include button-color-hover($white);
    @include button-color-active($white);
  }
}

@each $color, $value in $social-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
    @include button-color($white);
    @include button-color-hover($white);
  }
}

.btn-light,
.btn-white {
  @include button-color($gray-800);
  @include button-color-hover($gray-800);
}

.btn-outline-light,
.btn-outline-white {
  @include button-color-hover($gray-800);
  @include button-color-active($gray-800);
}

.btn-lg, .btn-group-lg > .btn{
  font-size: 1rem;
  font-weight: 700;
  font-family: $font-family-base;
}