.dotEnd{
    &::after{
        content: '';
        position: absolute;
        border-top: 1px solid #fff;
        border-top-style: dashed;
        z-index: 1;
        right: 0;
        top: 11px;
        width: calc(50% - 16px);
    }
}

.dotStart{
    &::before{
        content: '';
        position: absolute;
        border-top: 1px solid #fff;
        border-top-style: dashed;
        z-index: 1;
        left: 0;
        top: 11px;
        width: calc(50% - 16px);
    }
}

.figure{
    border-radius: 50%;
    &::after{
        content: '';
        position: absolute;
        width: 100%;
        background: rgba(255, 255, 255, 0.3);
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}