// Theme variables
@import "../../scss/1-variables/app";

.number{
    padding: .5rem;
    transform: translate(-50%, -50%);
}

.title{
    font-size: .7rem;
    color: inherit;
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
        font-size: 1rem;
    }
}