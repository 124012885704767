.rotate{
    &-0{
        transform: rotate(0deg);
    }
    &-45n{
        transform: rotate(-45deg);
    }
    &-90n{
        transform: rotate(-90deg);
    }
}