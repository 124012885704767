.presentationCardBg {
  height: 30rem;
}

.presentationCardWidth {
  max-width: 75rem;
}

.profileImageHeightMd {
  min-height: 14rem;
}

.profileImageHeightSm {
  height: 100px;
  width: 100px;
}

.logoImageHeight {
  height: 50px;
  width: auto;
}
