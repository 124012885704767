.hrText {
  position: relative;
  white-space: nowrap;
  margin: 35px 0;
}

.hrText:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hrText:after {
  content: attr(data-content);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 10px;
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  font-size: 13;
  --bs-text-opacity: 1;
  color: rgba(var(--bs-tertiary-rgb), var(--bs-text-opacity)) !important;
  font-weight: 600;
}

.messagesDiv {
  height: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
}
