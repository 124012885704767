/* .fullscreen {
  position: absolute;
  left:0;
  top:0;
  width: 100%;
  height: 100%;
} */

.dvs-WebViewer{
  width: 100% !important;
  height: 100% !important;
}