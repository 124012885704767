.cursor{
  &-grab {
    cursor: move !important;
    cursor: grab !important;
    cursor: -moz-grab !important;
    cursor: -webkit-grab !important;
  }

  &-default {
    cursor: default !important;
  }

  &-pointer {
    cursor: pointer !important;
  }
}