label{
  vertical-align: -moz-baseline-middle;
  vertical-align: -webkit-baseline-middle;
}

.form-control-lite {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  background: transparent;
  color: $form-control-lite-color;

  @include placeholder {
    color: rgba($form-control-lite-color, .5);
  }

  &:focus {
    box-shadow: none;
    outline: 0;
    background: transparent;
    color: $form-control-lite-color;
  }
}

.form-control{
  padding: 0.7rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: #12171F;
  &[type="text"], &[type="email"], &[type="password"]{
    box-shadow: 1px 1px 2px rgb(0 0 0 / 10%);

    &::-webkit-input-placeholder {
      color: rgba($form-control-lite-color, .25);
    }
  }
}

.form-check{
  display: flex;
  input{
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.lg-label .form-check-label {
  font-size: 1rem !important;
  font-weight: 700;
}

.form-check-label {
  font-weight: 400;
}

.form-check-input{

  & + label{
    font-size: 0.875rem;
    padding-top: 0.25em;
    font-weight: 400;
    color: #12171F;
  }

  &[type="radio"], &[type="checkbox"]{
    margin-right: 1em;
  }
  &[type="radio"]{
    width: 1.3em;
    height: 1.3em;
    &:checked{
      border: 1px solid rgba(18, 23, 31, 0.25);
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='7' cy='7' r='7' fill='%23E91D8F'/%3E%3C/svg%3E%0A");
      background-color: #FFF;
      background-size: 1em;
      background-position: center;
    }
  }
}

.inline-label{
  align-items: center;
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  & > *:first-of-type {
    width: 49%;
  }
  & >*:last-of-type {
    width: 48%;
  }

  input{
    text-align: right;
  }
}

.form-label{
  font-size: .875rem;
}

.hidden-label{
  label{
    visibility: hidden;
  }
}

$percSpace: $spacer * 0.75;
.field-percentage{
  position: relative;
  input{
    color: transparent !important;
  }
  &::after{
    content: attr(data-value) " %";
    position: absolute;
    left: calc($percSpace + 1px);
    top: 50%;
    transform: translateY(-50%);
  }
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label{
  color: $black;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked{
  background-color: #FFF;
}