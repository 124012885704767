.min-vw-25 { min-width: 25vw !important; }
.min-vw-50 { min-width: 50vw !important; }
.min-vw-75 { min-width: 75vw !important; }
.min-vh-25 { min-height: 25vh !important; }
.min-vh-30 { min-height: 30vh !important; }
.min-vh-50 { min-height: 50vh !important; }
.min-vh-75 { min-height: 75vh !important; }

.vw-10 { width: 10vw !important; }
.vw-25 { width: 25vw !important; }
.vw-50 { width: 50vw !important; }
.vw-75 { width: 75vw !important; }
.vh-10 { height: 10vh !important; }
.vh-25 { height: 25vh !important; }
.vh-50 { height: 50vh !important; }
.vh-75 { height: 75vh !important; }

$width-sizes: (
  auto: auto,
  10: 10%,
  15: 15%,
  20: 20%,
  25: 25%,
  30: 30%,
  35: 35%,
  40: 40%,
  48: 48%,
  60: 60%,
  65: 65%,
  70: 70%
);

@each $size, $value in $width-sizes {
  .w-#{$size} {
    width: $value !important;
  }

  .w-sm-#{$size} {
    @include media-breakpoint-up(sm) {
      width: $value !important;
    }
  }

  .w-md-#{$size} {
    @include media-breakpoint-up(md) {
      width: $value !important;
    }
  }

  .w-lg-#{$size} {
    @include media-breakpoint-up(lg) {
      width: $value !important;
    }
  }

  .w-xl-#{$size} {
    @include media-breakpoint-up(xl) {
      width: $value !important;
    }
  }

  .w-xxl-#{$size} {
    @include media-breakpoint-up(xxl) {
      width: $value !important;
    }
  }
}