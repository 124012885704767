.thumbnail {
  min-height: 150px !important;
  min-width: 150px !important;
  height: 150px !important;
  width: 150px !important;
}

.figure {
  top: 50%;
  width: 108% !important;
  transform: translate(-50%, -50%) scale(1.7);
  left: 90%;
  img {
    object-fit: cover;
  }
  &::after {
    content: '';
    position: absolute;
    width: 95%;
    background: rgba(0, 0, 0, 0.3);
    height: 95%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
}

@media only screen and (max-width: 1130px) {
  .figure {
    width: 108% !important;
  }
}

@media only screen and (max-width: 767px) {
  .figure {
    width: 100% !important;
    left: 50% !important;
    top: 100% !important;
  }
}

@media only screen and (max-width: 700px) {
  .figure {
    width: 100% !important;
  }
}

@media only screen and (max-width: 670px) {
  .figure {
    width: 100% !important;
  }
}

@media only screen and (max-width: 640px) {
  .figure {
    width: 105% !important;
  }
}

@media only screen and (max-width: 595px) {
  .figure {
    width: 110% !important;
  }
}

@media only screen and (max-width: 555px) {
  .figure {
    width: 120% !important;
  }
}

@media only screen and (max-width: 520px) {
  .figure {
    width: 130% !important;
  }
}

@media only screen and (max-width: 490px) {
  .figure {
    width: 140% !important;
  }
}

@media only screen and (max-width: 460px) {
  .figure {
    width: 150% !important;
  }
}

@media only screen and (max-width: 440px) {
  .figure {
    width: 160% !important;
  }
}

@media only screen and (max-width: 420px) {
  .figure {
    width: 170% !important;
  }
}

@media only screen and (max-width: 385px) {
  .figure {
    width: 180% !important;
  }
}

@media only screen and (max-width: 370px) {
  .figure {
    width: 190% !important;
  }
}

@media only screen and (max-width: 355px) {
  .figure {
    width: 210% !important;
  }
}

@media only screen and (max-width: 992px) {
  .milestoneTextDiv {
    min-height: 500px;
  }
}
