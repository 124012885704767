.scroll{
    &-y-auto {
      overflow-y: auto;
    }

    &-y-scroll {
      overflow-y: scroll;
    }

    &-x-auto {
      overflow-x: auto;
    }

    &-x-scroll {
      overflow-x: scroll;
    }

    &-auto {
      overflow: auto;
    }

    &-scroll {
      overflow: scroll;
    }

    &-hidden {
      overflow: hidden;
    }

    &-x-hidden {
      overflow-x: hidden;
    }

    &-y-hidden {
      overflow-y: hidden;
    }
}