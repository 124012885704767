// Spark
// Variables
@import '1-variables/minute_mortgage';

@import 'app';

#root {
  height: 100%;
}

.login-box {
  height: 30rem;
}

.disabled-screen {
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: absolute;
  background-color: #ffffff;
  opacity: 0.7;
  cursor: not-allowed;
}

// TODO: move to won files
div.dataTables_wrapper div.dataTables_info {
  padding: 0;
}

.page-item {
  .page-link {
    font-size: 0;
    &::after {
      font-size: 1rem;
    }
  }

  &:first-child .page-link:after {
    content: '<';
  }

  &:last-child .page-link:after {
    content: '>';
  }
}

.dataTables_length {
  font-size: 0;
  position: relative;
  &::before {
    content: 'Item per page';
    font-size: 0.6rem;
    position: absolute;
    left: 0;
    bottom: calc(100% + 10px);
  }

  select {
    min-width: 6rem;
    -webkit-appearance: menulist-button;
    -moz-appearance: menulist-button;
    appearance: menulist-button;
    border: 0 none;
    border-bottom: solid 1px var(--bs-gray-dark);
  }
}

select.form-control {
  appearance: menulist-button;
}

.dropdown-menu[data-bs-popper] {
  left: auto;
  right: 0;
}

// Select file
.minute-select {
  &__control{
    height: 2.8375rem;
    box-shadow: 1px 1px 2px rgb(0 0 0 / 10%);
  }
  &__dropdown-indicator,
  &__clear-indicator {
    padding: 0 8px !important;
  }

  &__value-container {
    padding: 0 8px !important;
  }
}

.places-autocomplete > div:first-of-type {
  height: 2.8375rem;
}

.was-validated {
  .minute-select .minute-select__control {
    border-color: var(--bs-success);
  }

  .minute-select.is-invalid .minute-select__control {
    border-color: var(--bs-danger);
  }
}

.img-thumbnail {
  height: 200px;
  width: 200px;
}

.cursor-pointer {
  cursor: pointer;
}

.btn:focus {
  box-shadow: none;
}

.dropdown-remove-caret {
  &::after {
    content: none;
  }
}

.intellisense-field {
  height: 5rem;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.intellisense-container {
  background-color: white;
  margin: 0;
  padding: 0;
  border: 1px solid #f0f0f0;
  width: 15rem;
  height: 10rem;
  overflow-x: hidden;
  overflow-y: auto;
  ul li {
    padding: 5px 10px 5px 10px;
    border-bottom: 1px solid #f0f0f0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ul li:last-child {
    border-bottom: none;
  }

  ul li:hover {
    background-color: $primary;
    color: $white;
  }
}

.intellisense-highlight {
  background-color: $primary;
  color: $white;
}

.dropdown .no-arrow::before,
.dropdown .no-arrow::after {
  border: none;
  content: none;
}

.table-vertical-scroll {
  height: 25rem;
  overflow: scroll;
}

.white-space-pre {
  white-space: pre-wrap;
}

.rounded-pill-left {
  border-top-left-radius: 50rem;
  border-bottom-left-radius: 50rem;
}

.rounded-pill-right {
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem;
}

.dropdown-rounded-pill-right > button {
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.rounded-card {
  border-radius: 1.125rem;
}

.rounded-card-body {
  border-top-left-radius: 1.125rem !important;
  border-top-right-radius: 1.125rem !important;
}

.rounded-card-footer {
  border-bottom-left-radius: 1.125rem !important;
  border-bottom-right-radius: 1.125rem !important;
}

.z-index-1 {
  z-index: 1;
}

.no-border-radius {
  border-radius: 0;
}

.vertical-line {
  border-right: 1px dashed gray;
  height: 39%;
  width: 0.445rem;
}


.form-check-input[type='checkbox'] {
  background: transparent url('../assets/icons/checkbox-disabled.svg') no-repeat center center;
  background-size: contain;
  width: 1.5em;
  height: 1.5em;
  border: 0 none;
  vertical-align: middle;
  outline: 0;
  box-shadow: none;
}

.form-check-input:checked[type='checkbox'] {
  background-image: url('../assets/icons/checkbox-checked.svg');
  background-color: transparent;
}

.checkbox-lite{
  .form-check-input[type='checkbox']{
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='3.5' y='3.5' width='17' height='17' rx='4.5' stroke='%23C4C4C4'/%3E%3C/svg%3E");
  }

  .form-check-input:checked[type='checkbox'] {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_511_1310)'%3E%3Cg filter='url(%23filter0_d_511_1310)'%3E%3Crect x='3.5' y='3.5' width='17' height='17' rx='4.5' stroke='%23C4C4C4' shape-rendering='crispEdges'/%3E%3C/g%3E%3Cpath d='M12.7782 16.1437C11.9971 16.9248 10.7308 16.9248 9.94975 16.1437L6.414 12.608C5.63307 11.827 5.63307 10.5609 6.414 9.77996C7.19571 8.99825 8.46339 8.99914 9.244 9.78196L11.364 11.9079L19.8477 3.41564C20.6286 2.63392 21.8959 2.63403 22.6772 3.41536C23.4583 4.19641 23.4587 5.46316 22.6777 6.24421L12.7782 16.1437Z' fill='%23EA178D'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d_511_1310' x='1' y='2' width='22' height='22' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='1'/%3E%3CfeGaussianBlur stdDeviation='1'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_511_1310'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_511_1310' result='shape'/%3E%3C/filter%3E%3CclipPath id='clip0_511_1310'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  }
}


.main-screen{
  background: transparent url(../assets/img/pos/pos_bg.png) no-repeat center;
  background-size: cover;
}

.label-mark{
  label{
    &::after{
      content: '*';
      font-weight: 700;
      font-size: 0.8em;
      vertical-align: top;
    }
  }
}

.icon-image{
  display: inline-block;
  vertical-align: middle;
  margin-right: 1em;
  img, svg{
    width: 1.42em;
    height: auto;
  }
}

.dropdown-toggle{
  &::after{
    content: none;
  }
}

.placeholder-color {
  color: #C4C4C4;
}

.cobranding-page-bg {
  background: url(../assets/img/cobranding-page/bg.png), linear-gradient(149.71deg, #EF5123 0%, #EA178D 50%, #9E4198 100%);
  background-blend-mode: multiply, normal;
}

.bg-pos-progress {
  background-color: #C1D959;
}

.text-lime {
  color: #C1D959;
}

.no-last-border-bottom:last-child {
  border-bottom: none !important;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #C4C4C4 !important;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #C4C4C4 !important;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #C4C4C4 !important;
}

.form-control.date-input-placeholder::-webkit-datetime-edit-text,
.form-control.date-input-placeholder::-webkit-datetime-edit-month-field,
.form-control.date-input-placeholder::-webkit-datetime-edit-day-field,
.form-control.date-input-placeholder::-webkit-datetime-edit-year-field {
  color: #C4C4C4 !important;
}