.item{
    &:not(:last-of-type)::before{
        content: "";
        position: absolute;
        top: 0.3em;
        left: calc(0.65em - 1px);
        bottom: -0.1em;
        border-right: dashed 2px var(--bs-primary);
    }
}

.itemDescription{
    h5::before{
        content: "";
        height: 1.25em;
        vertical-align: text-bottom;
        display: inline-block;
        width: 1px;
    }
}