.splash {
  user-select: none;
  position: fixed;
  background: $splash-bg;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 100;
  height: 100vh;
  transform: translateX(0%) translateY(-100%);
  transition: transform 0.5s;
  .splash-icon {
    display: block;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;    
  }

  &.active {
    transform: translateX(0%) translateY(0%);
    .splash-icon {
  
      &::after {
        box-sizing: border-box;
        left: 50%;
        display: block;
        border: 5px solid $splash-color;
        border-radius: 50%;
        content: '';
        top: 50%;
        margin-left: -20px;
        width: 40px;
        height: 40px;
        border-top-color: rgba(0, 0, 0, 0.2);
        animation: splash-spinner 1.2s linear infinite;
      }
    }
  }
}

@keyframes splash-spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(359deg); }
}
