.badge {
  color: #fff;
  width: 1em;
  height: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: inherit;
  span {
    font-size: 0.5em;
  }
}
