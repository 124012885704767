@each $color, $value in $theme-colors {
  @include background-variant(".bg-#{$color}-light", lighten($value, 20%));
}

@each $color, $value in $theme-colors {
  @include background-variant(".bg-#{$color}-dark", darken($value, 7.5%));
}

.bg-primary-gradient{
    background: $primary-gradient !important;
}