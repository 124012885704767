.header {
    position: relative;
}

.header-title {
    font-size: $h2-font-size;
    color: $header-color;
}

.header-subtitle {
    font-size: $h5-font-size;
    color: $header-color;
}