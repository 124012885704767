// Fonts
$font-family-base:'Montserrat', sans-serif;
$font-family-sans-serif:'Montserrat', sans-serif;
$headings-font-family: 'Roboto', sans-serif;
$font-weight-normal: 500;
$font-weight-bold: 700;
$font-size-base: 1rem;
$font-size-lg: 1.125rem !default;
$font-size-sm: .85rem !default;

// Layout
$max-box-width: 460px;

// Theme colors
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #12171F;
$opacityWhite: rgba(255,255,255,0.1);
$opacityWhite2: rgba(200,200,200,0.1);

$blue:    #65CBE5;
$indigo:  #6610f2 !default;
$purple:  #9E4198;
$pink:    #EA178D;
$light-pink: #F7ADD3;
$red:     #dc3545;
$orange:  rgb(240,83,36);
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;
$lime: rgb(93, 217, 89);
$gray: #808080;
$grayDark: #C4C4C4;

$primary:       $pink;
$primary-gradient: linear-gradient(-25deg, $orange, $pink, $purple);
$secondary:     $purple;
$tertiary:      $gray-500;
$success:       $green !default;
$info:          $blue;
$warning:       $orange !default;
$danger:        $red !default;
$light:         #E5E5E5;
$white: 	   #fff;
$dark: $black;

$theme-colors: (
	"primary": $primary,
	"secondary": $secondary,
	"tertiary": $tertiary,
	"success": $success,
	"info": $info,
	"warning": $warning,
	"danger": $danger,
	"light": $light,
	"dark": $dark,
    "done": $teal,
    "yellow": $yellow,
	"gray": $gray,
	"gray-dark": $grayDark,
	"light-pink": $light-pink,
	"white": $white,
);

// Body
$body-bg: #f4f7fc;

// Sidebar brand
$sidebar-brand-bg: $white;
$sidebar-brand-color: $white;

$header-height: 56px;

// Splash
$splash-bg: #f4f7fc;

// Wrapper
$wrapper-bg: #f4f7fc;

// Header
$header-color: $white;

// Breadcrumbs
$breadcrumb-active-color:           $white;
$breadcrumb-divider:                quote("/");
$breadcrumb-color:                  $white;

// Navbar
$navbar-padding-y: 0;
$navbar-theme-color:                rgba($black, .5);
$navbar-theme-hover-color:          rgba($black, .7);
$navbar-theme-active-color:         rgba($black, .9);
$navbar-theme-disabled-color:       rgba($black, .3);
//$navbar-theme-toggler-icon-bg:      str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-theme-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"), "#", "%23");
$navbar-theme-toggler-border-color: rgba($black, .1);
$navbar-theme-brand-color:          $navbar-theme-active-color;
$navbar-theme-brand-hover-color: 	  $navbar-theme-active-color;

// Sidebar general
$sidebar-bg: $white;
$sidebar-width: 214px;
$sidebar-font-size: 0.875rem;

// Sidebar brand
$sidebar-brand-bg: transparent;

// Sidebar content
$sidebar-content-bg: $white;

// Sidebar nav
$sidebar-nav-background: $white;

// Sidebar header
$sidebar-header-color: $white;

// Sidebar link
$sidebar-link-color: $dark;
$sidebar-link-bg: transparent;
$sidebar-link-hover-color:$info;
$sidebar-link-hover-bg: $opacityWhite;
$sidebar-link-active-color: $white;
$sidebar-link-active-bg: $dark;

// Sidebar child link
$sidebar-link-child-color: $dark;
$sidebar-link-child-bg: transparent;
$sidebar-link-child-hover-color: $info;
$sidebar-link-child-hover-bg: $opacityWhite2;
$sidebar-link-child-active-color: $sidebar-link-child-hover-color;
$sidebar-link-child-active-bg: $opacityWhite2;

// Sidebar link icon
$sidebar-link-icon-color: $dark;
$sidebar-link-icon-hover-color: $info;
$sidebar-link-icon-active-color: $dark;

// Splash
$splash-bg: $primary-gradient;

// Form control lite
$form-control-lite-color: $black;

// Hamburger
$hamburger-color: $primary;

// Splash
$splash-color: $gray-300;

// Wrapper
$wrapper-bg: $primary-gradient;

// Tables
$table-cell-padding-y: .5rem;
$table-cell-padding-x: .5rem;
$table-cell-padding-y-sm: .3rem !default;
$table-cell-padding-x-sm: .3rem !default;
$table-striped-bg: $gray-100 !default;